<template>
  <form @submit.prevent.stop="saveAndReload" class="row q-gutter-lg" v-if="entity && formFields">
    <div class="col">
      <div class="row justify-end q-py-md">
        <q-btn class="col col-auto" color="reseau" size="xs" outline @click="goToVizagence">{{$t('entities.go_to_vizagence')}}</q-btn>
      </div>
      <base-input-text v-bind="formInputProps('admin_note')" color="per"
        v-model="formData.admin_note" ref="admin_note" />

      <q-list bordered class="q-px-sm bg-white">
        <q-item>
          <q-item-section>
            <q-item-label overline>{{$t('entities.entity')}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>
            <q-item-label class="text-h6 items-end row">
              {{mainWallet['TITULAIRE']}}
              <q-icon v-if="entity.nom_entite_temporaire" class="q-ml-xs col col-shrink" size="1.6rem" color="reseau" name="warning" />
              <q-btn flat dense size="sm" :icon="is_favorite ? 'star' : 'star_border'" @click="toggleFavorite" />
            </q-item-label>
            <q-item-label caption>
              <ul class="row text-bold inline-list">
                <li>Créée le {{date_crea}}</li>
                <li>{{nb_pdv}} PDV</li>
                <li>{{nb_etp}} ETP Collaborateur </li>
              </ul>
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>
            <q-item-label class="row">
              <div class="col col-4" v-if="mainWallet['icra']">
                <h6 class="text-caption q-ma-none">ICRA</h6>
                <span>{{mainWallet['icra']}}</span>
              </div>
              <div class="col col-4" v-if="mainWallet['icv']">
                <h6 class="text-caption q-ma-none">ICAP</h6>
                <span>{{mainWallet['icv']}}</span>
              </div>
              <div class="col col-4" v-if="mainWallet['NOMCM1']">
                <h6 class="text-caption q-ma-none">CM</h6>
                <span>{{mainWallet['NOMCM1']}}</span>
              </div>
            </q-item-label>
            <q-item-label class="row">
              <div class="col col-4">
                <h6 class="text-caption q-ma-none">{{$t('entities.region')}}</h6>
                <span>{{entity.region.label}} ({{entityPostCode}})</span>
              </div>
              <div class="col col-4">
                <h6 class="text-caption q-mt-none">{{$t('entities.clubs')}}</h6>
                <p v-if="mainWallet['SEGMENT_ENTR']">Entreprise: {{mainWallet['SEGMENT_ENTR']}}</p>
                <p v-if="mainWallet['SEGMENT_AGRI']">Agri: {{mainWallet['SEGMENT_AGRI']}}</p>
                <p v-if="mainWallet['SEGMENT_CONS']">Construction: {{mainWallet['SEGMENT_CONS']}}</p>
                <p v-if="mainWallet['SEGMENT_VIE']">Segment vie: {{mainWallet['SEGMENT_VIE']}}</p>
                <p v-if="mainWallet['CLUBCOLL']">Collectives</p>
                <p v-if="mainWallet['CLUBPATRI']">Patrimoine</p>
              </div>
              <div class="col col-4" v-if="isAdmin">
                <base-input-text v-bind="formInputProps('postes')" color="per" dense
                  v-model="formData.postes" ref="postes" />
              </div>
              <div v-else>
                <h6 class="text-caption q-ma-none">{{ $t('entities.nbrPostes') }}</h6>
                <p v-html="formData.postes" />
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

      <AgentsList :agents="entity.agents" />

      <WalletsList :wallets="entity.wallets" v-model="formData['wallets']" />

      <div class="row items-stretch q-my-xl q-gutter-md">
        <div class="col">
          <h6 class="title-h6 q-ma-none">{{$t('entities.metrics.label')}}</h6>
          <div class="row q-my-md bg-white ">
            <div class="col">
              <q-list class="q-py-md">
                <q-item>
                  <q-item-section>
                    <q-item-label>{{$t('entities.metrics.comm_totale')}}</q-item-label>
                    <q-item-label class="text-h6">{{metrics.comm_totale}}</q-item-label>
                    <q-item-label caption>{{metrics.comm_totale_variation}}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item class="q-mt-md">
                  <q-item-section>
                    <q-item-label>{{$t('entities.metrics.sp_irt')}}</q-item-label>
                    <q-item-label class="text-h6">{{metrics.sp_irt}}</q-item-label>
                    <q-item-label caption>{{metrics.sp_irt_variation}}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item class="q-mt-md">
                  <q-item-section>
                    <q-item-label>{{$t('entities.metrics.ca_iard')}}</q-item-label>
                    <q-item-label class="text-h6">{{metrics.ca_iard}}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <q-separator vertical class="q-my-sm"/>
            <div class="col">
              <q-list class="q-py-md">
                <div class="q-pa-sm">
                  <q-item-label>{{$t('entities.metrics.tauxEntreeEtSortie')}}</q-item-label>
                  <div class="q-pa-md">
                    <div class="row">
                      <div class="col q-py-sm text-right">
                        <div class="q-pr-md">
                          Taux<br />d'entrée <span :style="{ color: colorVizagenceData }">IA</span>
                        </div>
                      </div>
                      <q-separator vertical/>
                      <div class="col q-py-sm row bar justify-between">
                        <div class="col bar-progress">
                          <div class="bar-progress-total">
                            <div class="bar-progress-value" :style="{ width: metrics.taux_entree_IA, background: colorVizagenceData }" />
                          </div>
                        </div>
                        <div class="col col-shrink q-pl-sm text-h6">
                          {{metrics.taux_entree_IA}}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col q-py-sm row bar justify-between">
                        <div class="col col-shrink q-pr-sm text-h6">
                          {{metrics.taux_sortie_IA}}
                        </div>
                        <div class="col bar-progress">
                          <div class="bar-progress-total">
                            <div class="bar-progress-value bar-progress-value--inverted" :style="{ width: metrics.taux_sortie_IA, background: colorVizagenceData }" />
                          </div>
                        </div>
                      </div>
                      <q-separator vertical/>
                      <div class="col q-py-sm">
                        <div class="q-pl-md">
                          Taux<br />de sortie <span :style="{ color: colorVizagenceData }">IA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <q-item>
                  <q-item-section>
                    <q-item-label>{{$t('entities.metrics.montant_total_prime_IA')}}</q-item-label>
                    <q-item-label class="text-h6">{{metrics.montant_total_prime_IA}}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label>{{$t('entities.metrics.solde_production_IA')}}</q-item-label>
                    <q-item-label class="text-h6">{{metrics.solde_production_IA}}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <h6>{{$t('entities.oscarr')}}</h6>
        <q-list class="bg-white q-py-md">
        </q-list>
      </div> -->
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <!-- <q-item class="q-pa-none">
          <q-toggle v-model="formData.is_red" ref="is_red" :disable="!isAdmin"
            :label="$t('entities.fields.is_red.label')" color="reseau"
          />
        </q-item> -->
        <q-item class="q-pa-none">
          <q-select :disable="!isAdmin" :options="segmentOptionsComputed" color="reseau" :label="$t('entities.fields.segment.label')"
            v-model="formData.segment" ref="segment" dense options-dense class="fit">
            <template v-slot:option="scope">
              <div class="q-px-sm q-mb-sm" v-if="scope.opt.value ==='_filter'">
                <q-input color="reseau" dense v-model="currentFilterSegmentValue" autofocus @keyup.enter="isDialogNewSegmentShown = false">
                  <template v-slot:append>
                    <q-icon v-show="currentFilterSegmentValue !== ''" name="close" @click="currentFilterSegmentValue = ''" class="cursor-pointer" />
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
              <div class="q-px-sm" v-else-if="scope.opt.value ==='_separator'">
                <q-separator class="q-my-sm"  />
              </div>
              <q-item v-bind="scope.itemProps" @click.stop.prevent="isDialogNewSegmentShown = true" v-else-if="scope.opt.value ==='_create'" >
                <q-item-section>
                  <span :class="`text-reseau`">{{ $t('form.create') }}</span>
                </q-item-section>
              </q-item>
              <q-item v-bind="scope.itemProps" @click.stop.prevent="isDialogManageSegmentShown = true" v-else-if="scope.opt.value ==='_manage'" >
                <q-item-section>
                  <span :class="`text-reseau`">{{ $t('entities.manageSegment') }}</span>
                </q-item-section>
              </q-item>
              <q-item v-else v-bind="scope.itemProps" v-on="scope.itemEvents" >
                <q-item-section>
                  {{ scope.opt.label }}
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:selected-item="scope">
              <q-chip @remove="unlinkSegment()" dense removable
                color="reseau" text-color="white" size="sm">
                <div>{{ scope.opt.label}}</div>
              </q-chip>
            </template>
          </q-select>
        </q-item>
        <q-item class="q-pt-sm column q-px-none">
          <div class="row q-ml-sm items-end">
            <q-icon class="col col-shrink" size="1.6rem" color="reseau" name="warning" />
            <span class="q-pl-sm col col-9" :style="{ 'line-height': '1' }">{{ $t('entities.fields.nom_entite_temporaire.label') }}</span>
          </div>
          <q-toggle v-model="formData.nom_entite_temporaire" ref="nom_entite_temporaire" :disable="!isAdmin"
            color="reseau"
          />
        </q-item>
      </q-list>

      <q-btn color="reseau full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
      <q-btn color="reseau full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
    </div>

    <!-- Dialog create segment -->
    <q-dialog v-model="isDialogNewSegmentShown"  persistent>
      <q-card style="min-width: 450px">
        <q-card-section class="q-pb-none">
          <div class="text-reseau text-uppercase text-h5">{{ $t('entities.newSegment') }}</div>
          <q-separator class="q-mt-sm q-mb-md" />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input color="reseau" dense v-model="labelNewSegment" :label="$t('entities.enterNewSegment')" autofocus @keyup.enter="isDialogNewSegmentShown = false" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary row q-mb-sm q-px-lg">
          <q-btn class="col" color="reseau" outline size="sm" :label="$t('form.cancel')" @click="isDialogNewSegmentShown = false" />
          <q-btn class="col" color="reseau" unelevated size="sm" :label="$t('form.submit')" @click="createSegment()" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog manage segment -->
    <q-dialog v-model="isDialogManageSegmentShown"  persistent>
      <q-card style="min-width: 450px">
        <q-card-section class="q-pb-none">
          <div class="text-reseau text-uppercase text-h5">{{ $t('entities.manageTheSegments') }}</div>
          <q-separator class="q-mt-sm q-mb-md" />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row" v-for="option in segmentOptionsToUpdate" :key="option.value">
            <div class="col">
              <div class="row">
                <div class="col col-9">
                  <q-input color="reseau" v-if="option.isEditing" dense v-model="option.label" />
                  <span :style="{ 'text-decoration':  option.isDeleting ? 'line-through' : 'unset' }" v-else>{{ option.label }}</span>
                </div>
                <div class="col">
                  <div class="row justify-end">
                    <q-btn class="q-px-sm" size="sm" icon="edit" flat @click="() => { option.isEditing = !option.isEditing; option.isEdited = true }" />
                    <q-btn class="q-px-none" size="sm" icon="delete" flat @click="() => { option.isDeleting = !option.isDeleting; option.isEdited = true }" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <q-separator class="q-my-sm" />
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary row q-mb-sm q-px-lg">
          <q-btn class="col" color="reseau" outline size="sm" :label="$t('form.cancel')" @click="isDialogManageSegmentShown = false" />
          <q-btn class="col" color="reseau" unelevated size="sm" :label="$t('form.save')" @click="updateSegments()" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'
import { AgentsList } from '../../components/agents'
import { WalletsList } from '../../components/wallets'
import { openURL } from 'quasar'

export default {
  name: 'EntityInformations',
  mixins: [BaseForm],
  props: {
    'form_model': { type: String, default: 'entities' },
    'action': { type: String, default: 'entities/saveEntity' }
  },
  components: { AgentsList, WalletsList },

  meta () {
    return { title: this.entity ? this.mainWallet['TITULAIRE'] : '' }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['entities/getFormFields']) {
      store.dispatch('entities/fetchFormFields')
    }
    store.dispatch('entities/getSegmentOptions')
  },

  data () {
    return {
      labelNewSegment: '',
      isDialogNewSegmentShown: false,
      segmentOptionsToUpdate: [],
      isDialogManageSegmentShown: false,
      currentFilterSegmentValue: '',
      colorVizagenceData: '#E77913'
    }
  },

  computed: {
    ...mapGetters({
      favorites: 'favorites/getFavorites',
      entity: 'entities/getCurrentEntity',
      segmentOptions: 'entities/getSegmentOptions',
      isAdmin: 'auth/isAdmin'
    }),

    segmentOptionsComputed () {
      let filteredSegmentOptions = []

      if (this.currentFilterSegmentValue === '') {
        filteredSegmentOptions = this.segmentOptions
      } else {
        const needle = this.currentFilterSegmentValue.toLowerCase()
        filteredSegmentOptions = this.segmentOptions.filter(option => option.label.toLowerCase().indexOf(needle) > -1)
      }

      return [
        { value: '_filter' },
        ...filteredSegmentOptions.map(segment => ({ label: segment.label, value: segment.id })),
        { value: '_separator' },
        { value: '_create' },
        { value: '_manage' }
      ]
    },
    is_favorite () {
      return !!this.favorites && !!this.favorites['entity'] && this.favorites['entity'].some(favorite => favorite.id === this.entity.id)
    },
    mainWallet () {
      let mainWallet = this.entity.wallets.find(wallet => wallet.CODEICX === wallet.CODE_RGT)
      if (mainWallet) return mainWallet
      return this.entity.wallets[0]
    },
    date_crea () {
      return this.$formatDate(this.mainWallet['DATE_CREA'], 'YYYY/MM/DD')
    },
    nb_pdv () {
      return this.entity.wallets.reduce((acc, wallet) => wallet['TYPE_RGT'] === 'PDV' ? acc + 1 : acc, 0)
    },
    nb_etp () {
      return this.entity.NB_ETP
    },

    metrics () {
      const MT_COMM_TOT_N1 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['MT_COMM_TOT_IA_N1']) + Number(wallet['MT_COMM_TOT_VS_N1']), 0)
      const MT_COMM_TOT_N2 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['MT_COMM_TOT_IA_N2']) + Number(wallet['MT_COMM_TOT_VS_N2']), 0)
      const comm = Math.floor(MT_COMM_TOT_N1 / 1000)

      const MT_COMM_TOT_VAR = (MT_COMM_TOT_N1 - MT_COMM_TOT_N2) / MT_COMM_TOT_N2

      const comm_variation = Math.floor(MT_COMM_TOT_VAR * 1000) / 10
      const comm_sign = comm_variation > 0 ? '+' : ''

      const MT_SIN_ATTR_N3_N1 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['MT_SIN_ATTR_N3_N1']), 0)
      let MT_PRIMES_N3_N1 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['MT_PRIMES_N3_N1']), 0)
      const TX_SP_MUT_N3_N1 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['TX_SP_MUT_N3_N1']), 0)

      const MT_SIN_ATTR_N4_N2 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['MT_SIN_ATTR_N4_N2']), 0)
      let MT_PRIMES_N4_N2 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['MT_PRIMES_N4_N2']), 0)
      const TX_SP_MUT_N4_N2 = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['TX_SP_MUT_N4_N2']), 0)

      // sp/irt N-1: ( MT_SIN_ATTR_N3_N1 / MT_PRIMES_N3_N1) + TX_SP_MUT_N3_N1,
      // sp/irt N-2: ( MT_SIN_ATTR_N4_N2 / MT_PRIMES_N4_N2) + TX_SP_MUT_N4_N2
      if (!MT_PRIMES_N3_N1) { MT_PRIMES_N3_N1 = Infinity }
      if (!MT_PRIMES_N4_N2) { MT_PRIMES_N4_N2 = Infinity }

      const sp_irt_n1 = MT_SIN_ATTR_N3_N1 / MT_PRIMES_N3_N1 + TX_SP_MUT_N3_N1
      const sp_irt_n2 = MT_SIN_ATTR_N4_N2 / MT_PRIMES_N4_N2 + TX_SP_MUT_N4_N2

      const sp_irt_variation = sp_irt_n1 - sp_irt_n2
      const sp_irt_sign = sp_irt_variation > 0 ? '+' : ''

      const ca_iard = this.entity['CA_IARD'] || 0
      const caIard = this.$formatNumber(ca_iard)

      const taux_entree_IA = this.entity['taux_entree_IA'] || 0
      const tauxEntreeIA = Math.round(taux_entree_IA)

      const taux_sortie_IA = this.entity['taux_sortie_IA'] || 0
      const tauxSortieIA = 100 - Math.round(taux_sortie_IA)

      const montant_total_prime_IA = this.entity['montant_total_prime_IA'] || 0
      const montantTotalPrimeIA = this.$formatNumber(montant_total_prime_IA)

      const solde_production_IA = this.entity['solde_production_IA'] || 0
      const soldeProductionIA = this.$formatNumber(solde_production_IA)

      return {
        comm_totale: `${comm}K€`,
        comm_totale_variation: `${comm_sign}${comm_variation}%`,
        sp_irt: `${Math.round(sp_irt_n1 * 1000) / 10}%`,
        sp_irt_variation: `${sp_irt_sign}${Math.round(sp_irt_variation * 1000) / 10}%`,
        ca_iard: `${caIard}€`,
        taux_entree_IA: `${tauxEntreeIA}%`,
        taux_sortie_IA: `${tauxSortieIA}%`,
        montant_total_prime_IA: `${montantTotalPrimeIA}€`,
        solde_production_IA: `${soldeProductionIA}€`
      }
    },
    peda () {
      const NB_AN_PEDA_PRO_CN = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['NB_AN_PEDA_PRO_CN']), 0)
      const NB_AN_PEDA_PRO_CIBLE = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['NB_AN_PEDA_PRO_CIBLE']), 0)
      const NB_AN_PEDA_ENTR_AGRI_CN = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['NB_AN_PEDA_ENTR_AGRI_CN']), 0)
      const NB_AN_PEDA_ENTR_AGRI_CIBLE = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['NB_AN_PEDA_ENTR_AGRI_CIBLE']), 0)
      const NB_AN_PEDA_PREV_CN = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['NB_AN_PEDA_PREV_CN']), 0)
      const NB_AN_PEDA_PREV_CIBLE = this.entity.wallets.reduce((sum, wallet) => sum + Number(wallet['NB_AN_PEDA_PREV_CIBLE']), 0)

      return {
        NB_AN_PEDA_PRO_CN,
        NB_AN_PEDA_PRO_CIBLE,
        NB_AN_PEDA_ENTR_AGRI_CN,
        NB_AN_PEDA_ENTR_AGRI_CIBLE,
        NB_AN_PEDA_PREV_CN,
        NB_AN_PEDA_PREV_CIBLE
      }
    },
    entityClubStr () {
      if (!this.entity) return ''

      let str = ''
      const clubs = { 'CLUBAGRI': 'A', 'CLUBENTR': 'E', 'CLUBPATRI': 'P' }
      Object.keys(clubs).forEach(key => { str += this.mainWallet[key] ? ` ${clubs[key]} ` : ' - ' })
      return str
    },
    entityPostCode () {
      return this.mainWallet['CDPOSTAL'].substr(0, 2)
    }
  },

  watch: {
    entity (newVal, oldVal) {
      this.formData = {
        ...this.formData,
        id: newVal.id,
        ...this.formFields.map(({ id, options }) => ({ id, options }))
          .reduce((acc, { id, options }) => ({
            ...acc, [id]: options ? options.find(({ value }) => value === newVal[id]) : newVal[id]
          }), {}),
        wallets: newVal.wallets.map(({ id, comm_ajustee, mdt_gest }) => ({
          id,
          comm_ajustee: Number(comm_ajustee),
          mdt_gest: Number(mdt_gest)
        })),
        nom_entite_temporaire: newVal.nom_entite_temporaire,
        segment: newVal.segment ? { label: newVal.segment.label, value: newVal.segment.id } : null
      }
    },

    segmentOptions (newVal) {
      this.segmentOptionsToUpdate = newVal.map(option => ({
        ...option,
        isDeleting: false,
        isEditing: false,
        isEdited: false
      }))
    },

    formData: {
      handler (newVal, oldVal) {
        // console.log(newVal)
      },
      deep: true
    }
  },

  mounted () {
    this.formData = {
      ...this.formData,
      wallets: []
    }
  },

  methods: {
    toggleFavorite () {
      if (!this.is_favorite) {
        this.$store.dispatch('favorites/saveFavorite', { model_type: 'entity', model_id: this.entity.id })
          .then(() => { this.$q.notify({ message: this.$t(`favorites.save_success`), color: 'positive' }) })
          .then(() => { this.$store.dispatch('config/fetchCounts') })
      } else {
        this.$store.dispatch('favorites/deleteFavorite', { model_type: 'entity', model_id: this.entity.id })
          .then(() => { this.$q.notify({ message: this.$t(`favorites.delete_success`), color: 'positive' }) })
          .then(() => { this.$store.dispatch('config/fetchCounts') })
      }
    },

    saveAndReload () {
      if (this.formData.segment) this.formData.segment_id = this.formData.segment.value
      else this.formData.segment_id = null
      delete this.formData.segment

      this.save().then(() => {
        this.$store.dispatch('entities/getEntity', this.$route.params.id)
      })
    },

    createSegment () {
      this.$store.dispatch('entities/saveSegment', { label: this.labelNewSegment })
        .then(({ id, label }) => {
          this.$store.dispatch('entities/getSegmentOptions')
            .then(() => {
              this.formData.segment = { label, value: id }
              this.hasChanged = true
              this.isDialogNewSegmentShown = false
              this.labelNewSegment = ''
              this.$q.notify({ message: this.$t(`entities.segment_added`), color: 'positive' })
            })
        })
    },
    updateSegments () {
      let countProcessed = 0
      const segmentsEdited = this.segmentOptionsToUpdate.filter(option => option.isEdited)
      if (segmentsEdited.length === 0) this.isDialogManageSegmentShown = false

      const callbackProcessed = () => {
        countProcessed++
        if (countProcessed === segmentsEdited.length) {
          this.$store.dispatch('entities/getSegmentOptions')
          this.isDialogManageSegmentShown = false
          this.$q.notify({ message: this.$t(`entities.segments_updated`), color: 'positive' })
        }
      }

      segmentsEdited.forEach(({ label, id, isDeleting }) => {
        if (isDeleting) {
          this.$store.dispatch('entities/deleteSegment', id)
            .then(callbackProcessed)
            .then(() => {
              console.log(this.formData.segment, id)
              if (this.formData.segment && this.formData.segment.value === id) this.unlinkSegment()
            })
        } else {
          this.$store.dispatch('entities/saveSegment', { label, id })
            .then(callbackProcessed)
        }
      })
    },
    unlinkSegment () {
      this.formData.segment = null
    },

    goToVizagence () {
      openURL(`https://www.vizagence.gan.fr/search?q=${this.entity['CODEICX']}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-linear-progress
  height: 1rem
  margin: 0 0 0.5rem

.inline-list
  padding: 0 1rem

  li
    margin-right: 3rem

.bar
  .bar-progress
    height: 40px
    .bar-progress-total
      background-color: #e9ecef
      font-size: .75rem
      height: 100%
      overflow: hidden
      width: 100%
      position: relative

      .bar-progress-value
        position: absolute
        left: 0
        bottom: 0
        height: 100%

        &.bar-progress-value--inverted
          right: 0
          left: unset
</style>
